var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"meep-table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"calculate-widths":true,"data-app":true,"headers":_vm.headers,"height":_vm.height,"hide-default-footer":true,"item-class":function () { return 'meep-table-row'; },"items":_vm.items,"sort-by":_vm.sortBy,"fixed-header":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('div',{key:h.key,staticClass:"meep-table-header"},[(header.component)?_c(header.component,{tag:"component",staticClass:"app-icon-table",class:header.iconClass}):_c('span',[_vm._v(_vm._s(header.text))]),(header.tooltip)?_c('md-tooltip',{attrs:{"md-direction":"bot"}},[_vm._v(_vm._s(header.tooltip))]):_vm._e()],1)]}}}),_vm._l((_vm.buttons),function(button,key){return {key:("item." + ([button.value])),fn:function(ref){
var item = ref.item;
return [_c('md-button',{key:key,staticClass:"md-icon-button",attrs:{"disabled":button.readOnly(item)},on:{"click":function($event){$event.stopPropagation();return _vm.goTo(item, button)}}},[(button.value === 'delete')?_c('MeepIconDelete',{staticClass:"app-icon"}):_vm._e()],1)]}}}),_vm._l((_vm.checkboxes),function(checkbox,key){return {key:("item." + ([checkbox.value])),fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{key:key,attrs:{"disabled":checkbox.readOnly(item),"off-icon":"mdi-checkbox-blank-circle-outline","on-icon":"mdi-checkbox-marked-circle"},model:{value:(item[checkbox.value]),callback:function ($$v) {_vm.$set(item, checkbox.value, $$v)},expression:"item[checkbox.value]"}})]}}}),_vm._l((_vm.formats),function(format,key){return {key:("item." + ([format.value])),fn:function(ref){
var item = ref.item;
return [_c('p',{key:key},[_vm._v(_vm._s(format.format(item[format.value])))])]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }